<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
        <ConfirmDialog></ConfirmDialog>

		<div class="grid p-fluid stickBar">
            <div class="col-12">
                <div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems" :popup="true" :key="menuKey">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
                                <div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
                                    {{ mainData.teklifBasligi }}
                                </div>
                               
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)" />
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

        <div class="grid dashboard">
            <div class="col-12 md:col-4 lg:col-3">
                <div class="card green-bgcolor solid-surface-text-color p-3">
                    <div class="flex align-items-center">
                        <h6>TEKLİF NO</h6>
                    </div>
                    <div class="flex justify-content-between mt-1 flex-wrap">
                        <div class="flex flex-column" >
                            <span class="mb-1 fs-large">{{ mainData.teklifNo }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-4 lg:col-3">
                <div class="card pink-bgcolor solid-surface-text-color p-3">
                    <div class="flex align-items-center">
                        <h6>SİPARİŞ NO</h6>
                    </div>
                    <div class="flex justify-content-between mt-1 flex-wrap">
                        <div class="flex flex-column" >
                            <span class="mb-1 fs-large">{{ mainData.siparisNo == null ? '-' : mainData.siparisNo }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-4 lg:col-3">
                <div class="card cyan-bgcolor solid-surface-text-color p-3">
                    <div class="flex align-items-center">
                        <h6>DURUM AÇIKLAMASI</h6>
                    </div>
                    <div class="flex justify-content-between mt-1 flex-wrap">
                        <div class="flex flex-column" >
                            <span class="mb-1 fs-large">{{ mainData.durumAciklamasiName }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-4 lg:col-3">
                <div class="card purple-bgcolor solid-surface-text-color p-3">
                    <div class="flex align-items-center">
                        <h6>GENEL TOPLAM</h6>
                    </div>
                    <div class="flex justify-content-between mt-1 flex-wrap">
                        <div class="flex flex-column" >
                            <span class="mb-1 fs-large">{{ formatNumber(mainData.genelToplam) }} {{ getCurrency() }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="YEDEK PARÇA TEKLİF/SİPARİŞ" entityName="bm_yedekparcateklifsiparis">
					</EntityHeader>

                    <Message v-if="mainData.tezgahName == '289421' || mainData.tezgahName == '292719' || mainData.tezgahName == '291433' || mainData.tezgahName == '297434'" severity="error">UYARI!!! ATC Kol değiştirilmiştir. Parça kodunu ve önceki teklifleri kontrol ediniz.</Message>
                    
                    <Message v-if="mainData.pesinMi == true" :closable="false">Seçili firmanın Ödeme koşulu PEŞİN'dir</Message>

					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="teklifBasligi">Teklif Başlığı <span style="color:red">*</span></label>
								<InputText id="teklifBasligi" type="text" v-model="mainData.teklifBasligi" :disabled="isDisabled" />
                                <span v-if="v$.mainData.teklifBasligi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.teklifBasligi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="teklifRevizeNo">Teklif Revize No</label>
								<InputNumber ref="aaa" id="teklifRevizeNo" v-model="mainData.teklifRevizeNo" mode="decimal" locale="tr-TR" :minFractionDigits="0" :min="0" :showButtons="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="musteriReferansNo">Müşteri Sipariş Numarası</label>
								<InputText id="musteriReferansNo" type="text" v-model="mainData.musteriReferansNo" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="teklifTarihi">Teklif Tarihi <span style="color:red">*</span></label>
								<Calendar showButtonBar id="teklifTarihi" v-model="mainData.teklifTarihi" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
                                <span v-if="v$.mainData.teklifTarihi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.teklifTarihi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>

                        <div class="col-3">
							<div class="field p-fluid">
								<div class="field p-fluid">
                                    <EntityLookupPopup  id="tezgah" v-model="mainData.tezgahName" ref="entity_tezgah" 
                                        label="Tezgah" entityName="bm_unite" nameField="bm_serino" :state="true" :disabled="isDisabled"
                                        @itemSelected="tezgahSelected = $event" @itemCleared="tezgahSelected = null"
                                        parentFieldName="bm_kurulummusteriid" :parentId="firmaSelected"
                                        secondNameField="bm_urunid" secondNameFieldType="lookup"
                                        :CrmDataTable_options="tezgah_CrmDataTable_options">
                                    </EntityLookupPopup>
                                </div>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="urun" v-model="mainData.urunName" ref="entity_urun" label="Tezgah Modeli"
									entityName="product" nameField="name" :state="true" :disabled="isDisabled"
									@itemSelected="urunSelected = $event" @itemCleared="urunSelected = null"
                                    secondNameField="productnumber">
								</EntityLookup>
							</div>
						</div>
                        <div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="firma" v-model="mainData.firmaName" ref="entity_firma" label="Firma"
									entityName="account" nameField="name" :state="true" :required="true" :disabled="isDisabled"
									@itemSelected="firmaSelected = $event" @itemCleared="firmaSelected = null">
								</EntityLookup>
                                <span v-if="v$.mainData.firmaId.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.firmaId.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="ilgilikisi" v-model="mainData.ilgiliKisiName" ref="entity_ilgilikisi"
									label="İlgili Kişi" entityName="contact" nameField="fullname" :state="true" :disabled="isDisabled"
									parentFieldName="parentcustomerid" :parentId="firmaSelected"
									@itemSelected="ilgilikisiSelected = $event" @itemCleared="ilgilikisiSelected = null">
								</EntityLookup>
							</div>
						</div>

						<div class="col-3">
							<div class="field p-fluid">
								<label for="siparisTarihi">Sipariş Tarihi</label>
								<Calendar showButtonBar id="siparisTarihi" v-model="mainData.siparisTarihi" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
							</div>
						</div>
						
						<div class="col-3">
							<div class="field p-fluid">
								<label for="turu">Türü</label>
								<Dropdown id="turu" v-model="mainData.turu" :options="SM_turu" optionLabel="Value" optionValue="AttributeValue" placeholder="Türü" :showClear="true" :disabled="isDisabled" />
                            </div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="fiyatTuru">Fiyat Türü</label>
								<Dropdown id="fiyatTuru" v-model="mainData.fiyatTuru" :options="SM_fiyatTuru" optionLabel="Value" optionValue="AttributeValue" placeholder="Fiyat Türü" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
                        <div class="col-3">
							<div class="field p-fluid">
								<label for="urgencyCode">Urgency Code</label>
								<Dropdown id="urgencyCode" v-model="mainData.urgencyCode" :options="SM_urgencyCode" optionLabel="Value" optionValue="AttributeValue" placeholder="Urgency Code" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
                        <div class="col-3">
							<div class="field p-fluid">
								<label for="alternatifkisi">Alternatif İlgili Kişi</label>
								<InputText id="alternatifkisi" type="text" v-model="mainData.alternatifKisi" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="vadeSuresi">Vade Süresi</label>
								<Dropdown id="vadeSuresi" v-model="mainData.vadeSuresi" :options="SM_vadeSuresi" optionLabel="Value" optionValue="AttributeValue" placeholder="Vade Süresi" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="pesinMi">Peşin Mi?</label>
								<Dropdown id="pesinMi" v-model="mainData.pesinMi" :options="SM_pesinMi" optionLabel="Value" optionValue="AttributeValue" placeholder="Peşin Mi?" :showClear="true" @change="changePesinmi" :disabled="isDisabled" />
							</div>
						</div>
                        <div class="col-3">
							<div class="field p-fluid">
								<label for="durumAciklamasi">Durum Açıklaması</label>
								<Dropdown id="durumAciklamasi" v-model="mainData.durumAciklamasi" :options="SM_durumAciklamasi" optionLabel="Value" optionValue="AttributeValue" placeholder="Durum Açıklaması" @change="changeDurumAciklamasi" :disabled="isDisabled" />
							</div>
						</div>
					</div>

                    <div class="grid">
                        <div class="col-4">
							<div class="field p-fluid">
								<label for="teslimKosullari">Teslim Koşulları</label>
								<Textarea id="teslimKosullari" rows="2" :autoResize="false" v-model="mainData.teslimKosullari" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="odemeKosullari">Ödeme Koşulları</label>
								<Textarea id="odemeKosullari" rows="2" :autoResize="false" v-model="mainData.odemeKosullari" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-5">
							<div class="field p-fluid">
								<label for="genelAciklama">Genel Açıklama (Raporlarda çıkacaktır)</label>
								<Textarea id="genelAciklama" rows="2" :autoResize="false" v-model="mainData.genelAciklama" :disabled="isDisabled" />
							</div>
						</div>
                        <div class="col-3" v-if="mainData.turu == 930710010">
                            <div class="field p-fluid">
								<EntityLookup id="servisTalebi" v-model="mainData.servisTalebiName" ref="entity_servisTalebi"
									label="Servis Talebi" entityName="incident" nameField="ticketnumber" secondNameField="title"
									@itemSelected="servisTalebiSelected = $event" @itemCleared="servisTalebiSelected = null"
									parentFieldName="bm_uniteid" :parentId="tezgahSelected"></EntityLookup>
							</div>
                        </div>
                    </div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>DETAYLAR</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="faturaTarihi">Fatura Tarihi</label>
								<Calendar showButtonBar id="faturaTarihi" v-model="mainData.faturaTarihi" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="faturaNo">Fatura No</label>
								<InputText id="faturaNo" type="text" v-model="mainData.faturaNo" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="faturaToplami">Fatura Toplamı</label>
								<InputNumber id="faturaToplami" v-model="mainData.faturaToplami" mode="decimal" locale="tr-TR" :minFractionDigits="0" :min="0" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="ymtrTeklifNo">YMTR Teklif No</label>
								<InputText id="ymtrTeklifNo" type="text" v-model="mainData.ymtrTeklifNo" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-12">
							<div class="field p-fluid">
								<label for="notAriza">Not Arıza</label>
								<Textarea id="notAriza" rows="1" :autoResize="false" v-model="mainData.notAriza" :disabled="isDisabled" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>TOPLAMLAR</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="araToplam">Ara Toplam</label>
								<InputNumber id="araToplam" v-model="mainData.araToplam" mode="decimal" locale="tr-TR" :minFractionDigits="2" disabled />
							</div>
						</div>
                        <div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="paraBirimi" v-model="mainData.paraBirimiName" ref="entity_paraBirimi"
									label="Para Birimi" entityName="transactioncurrency" nameField="currencyname" :state="true" :disabled="isDisabled"
									@itemSelected="paraBirimiSelected = $event" @itemCleared="paraBirimiSelected = null"></EntityLookup>
                                <span v-if="v$.mainData.paraBirimiId.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.paraBirimiId.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-6">
							<div class="field p-fluid">
                                <label for="SonGonderilenServisRaporuMailAdresi">Son Servis Raporu Mail Adresi</label>
                                <InputText id="SonGonderilenServisRaporuMailAdresi" type="text" v-model="mainData.SonGonderilenServisRaporuMailAdresi" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="masrafToplami">Masraf Toplamı</label>
								<InputNumber id="masrafToplami" v-model="mainData.masrafToplami" mode="decimal" locale="tr-TR" :minFractionDigits="2" @input="changeMasrafToplami" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-9">
							<div class="field p-fluid">
								<label for="masrafAciklama">Masraf Açıklama</label>
								<InputText id="masrafAciklama" type="text" v-model="mainData.masrafAciklama" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="indirimToplami">İndirim Toplamı</label>
								<InputNumber id="indirimToplami" v-model="mainData.indirimToplami" mode="decimal" locale="tr-TR" :minFractionDigits="2" @input="changeIndirimToplami" :disabled="isDisabled"/>
							</div>
						</div>
						<div class="col-9">
							<div class="field p-fluid">
								<label for="indirimAciklama">İndirim Açıklama</label>
								<InputText id="indirimAciklama" type="text" v-model="mainData.indirimAciklama" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="genelToplam">Genel Toplamı</label>
								<InputNumber id="genelToplam" v-model="mainData.genelToplam" mode="decimal" locale="tr-TR" :minFractionDigits="2" disabled/>
							</div>
						</div>
						<div class="col-9">
							<div class="field p-fluid">
								<label for="genelToplamAciklama">Genel Toplam Açıklama</label>
								<InputText id="genelToplamAciklama" type="text" v-model="mainData.genelToplamAciklama" :disabled="isDisabled" />
							</div>
						</div>
						

					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>TEKLİF/SİPARİŞ ÜRÜNLERİ</h5>
					<DataTable :value="teklifUrunleriData"
                        v-model:selection="selectedSiparisUrunleri"
                        :rowHover="true"
                        :resizableColumns="true"
                        columnResizeMode="fit"
                        scrollDirection="horizontal"
                        :show-gridlines="true"
                        :striped-rows="true"
                        editMode="cell" @cell-edit-complete="aramaSonuclariOnCellEditComplete" @cell-edit-init="aramaSonuclariOnCellEditInit"
                        :scrollable="true">
						<template #empty>
							Kayıt Bulunamadı.
						</template>
						<template #loading>
							Veriler yükleniyor, lütfen bekleyiniz...
						</template>
                        <template #header>
							<div class="flex justify-content-between">
								<div style="width: 30%;">
									<Button label="Seçimi Temizle" icon="pi pi-trash" class="p-button p-button-success" @click="selectedSiparisUrunleri = []" />
								</div>
								<div>
                                    <Button v-if="getDirtyTeklifUrunleriData.length > 0" label="Tümünü Kaydet" icon="pi pi-save" class="p-button p-button-info" @click="confirmAllSaveProduct()"  />
                                    <Button v-if="selectedSiparisUrunleri.length > 0" :label="siparisUrunleriCount" icon="pi pi-trash" class="p-button p-button-danger" @click="confirmSelectedDeleteProducts()" :disabled="isDisabled" />
								</div>
							</div>
						</template>
                        <Column selectionMode="multiple" style="flex: 0 0 3rem" headerStyle="width: 3rem"></Column>
						<Column field="siraNo" header="Sıra" style="flex: 0 0 3rem"></Column>
                        <Column field="yedekParcaKodu" header="Ürün kodu" style="flex: 0 0 14rem">
                            <template #editor="{ data, field }">
								<InputText v-model="data[field]" type="search" inputStyle="flex: 0 0 10rem;" :disabled="isDisabled"/>
							</template>
                        </Column>
						<Column field="yedekParcaName" header="Ürün" ></Column>
						
						<Column field="birimFiyat" header="Birim Fiyat" style="flex: 0 0 10rem; justify-content: right;" >
							<template #body="slotProps">
                                {{ formatNumber(slotProps.data.birimFiyat) }} {{ getCurrency() }}
							</template>
                            <template #editor="{ data, field }">
								<InputNumber v-model="data[field]" mode="decimal" :minFractionDigits="2" inputStyle="width: 80px" :disabled="isDisabled" />
							</template>
						</Column>
                        <Column field="adet" header="Adet" style="flex: 0 0 7rem; justify-content: center;">
                            <template #editor="{ data, field }">
								<InputNumber v-model="data[field]" mode="decimal" showButtons :min="0" :max="1000" inputStyle="width: 40px" :disabled="isDisabled" />
							</template>
                        </Column>
						<Column field="toplamFiyat" header="Toplam Fiyat" style="flex: 0 0 8rem; justify-content: right;">
							<template #body="slotProps">
                                <div style="text-align: center">
                                    <span>{{ formatNumber(slotProps.data.toplamFiyat) }} {{ getCurrency() }}</span>
                                </div>
							</template>
						</Column>
						<Column field="teslimSuresi" header="Teslim Süresi" style="flex: 0 0 13rem; justify-content: left;">
                            <template #editor="{ data, field }">
								<InputText v-model="data[field]" type="search" inputStyle="width:10px" @input="data[field] = $event.target.value.toUpperCase()" :disabled="isDisabled" />
							</template>
                        </Column>
                        <Column :exportable="false" style="flex: 0 0 5rem; min-width:5rem; justify-content: center;" v-if="isDisabled == false">
							<template #body="slotProps">
								<Button v-if="slotProps.data.dirtyField > 0" icon="pi pi-save" class="p-button-rounded p-button-info" @click="confirmSaveProduct(slotProps.data)" :disabled="isDisabled" />
							</template>
						</Column>
					</DataTable>
				</div>
			</div>

            <div class="col-12">
                <Annotation objectTypeCode="10011" logicalName="bm_yedekparcateklifsiparis" :objectId="entityId" ></Annotation>
            </div>
		</div>

		<Dialog v-model:visible="displayTeklif" :breakpoints="{'960px': '90vw', '640px': '90vw'}" :style="{width: '90vw'}" header="Ürün Ekle" :modal="true" :closable="true" :showHeader="true" :draggable="true">
            <Bm_yedekparcateklifsiparisTeklif @modalKapansin="onModalKapansin" :firmaId="mainData.firmaId"></Bm_yedekparcateklifsiparisTeklif>
        </Dialog>

        <Dialog v-model:visible="displaySiparisUrun" :breakpoints="{'960px': '90vw', '640px': '90vw'}" :style="{width: '90vw'}" header="Ürün Düzenle" :modal="true" :closable="true" :showHeader="true" :draggable="true" @show="showUrunDuzenle">
            <br>
            <div class="p-4">
                <div class="grid">
                    <div class="col-3">
                        <div class="field p-fluid">
                            <label for="siraNo">Sıra</label>
                            <InputText id="siraNo" type="text" v-model="dataSiparisUrun.siraNo" />
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="field p-fluid">
                            <label for="yedekParcaKodu">Yedek Parça Kodu</label>
                            <InputText id="yedekParcaKodu" type="text" v-model="dataSiparisUrun.yedekParcaKodu" />
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="field p-fluid">
                            <EntityLookupPopup id="yedekParca" v-model="dataSiparisUrun.yedekParcaName" ref="entity_yedekParca"
                                label="Yedek Parça" entityName="bm_yedekparca" nameField="bm_yedekparcaadi" :state="true"
                                @itemSelected="yedekParcaSelected = $event" @itemCleared="yedekParcaSelected = null"
                                :CrmDataTable_options="yedekParca_CrmDataTable_options">
                            </EntityLookupPopup>
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="field p-fluid">
                            <label for="teslimSuresi">Teslim Süresi</label>
                            <InputText id="teslimSuresi" type="text" v-model="dataSiparisUrun.teslimSuresi" />
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="field p-fluid">
                            <label for="tahminiTeslimEdilmeTarihi">Teslim Edilme Tarihi</label>
                            <Calendar showButtonBar id="tahminiTeslimEdilmeTarihi" v-model="dataSiparisUrun.tahminiTeslimEdilmeTarihi" autocomplete="off" :showIcon="true" :showTime="false" />
                        </div>
                    </div>
                    
                    
                    <div class="col-3">
                        <div class="field p-fluid">
                            <label for="birimFiyat">Birim Fiyat</label>
                            <InputNumber id="indirimToplami" v-model="dataSiparisUrun.birimFiyat" mode="decimal" locale="tr-TR" :minFractionDigits="2" @input="changeBirimFiyat" @focus="focusBirimFiyat" />
                        </div>
                    </div>

                    <div class="col-3">
                        <div class="field p-fluid">
                            <label for="adet">Adet</label>
                            <InputNumber id="adet" v-model="dataSiparisUrun.adet" mode="decimal" showButtons :min="0" :max="1000" @input="changeAdet" />
                        </div>
                    </div>
                    
                    <div class="col-3">
                        <div class="field p-fluid">
                            <label for="toplamFiyat">Toplam Fiyat</label>
                            <InputNumber id="toplamFiyat" v-model="dataSiparisUrun.toplamFiyat" mode="decimal" locale="tr-TR" :minFractionDigits="2" disabled />
                        </div>
                    </div>
                </div>

                <Button v-if="dataSiparisUrun.birimFiyat" label="Kaydet" class="mt-3 p-button-secondary" icon="pi pi-save" @click="OnSaveTeklifSiparisUrun" />
            </div>
            
        </Dialog>

        <Dialog v-model:visible="displayIndir" :breakpoints="{'960px': '90vw', '640px': '90vw'}" :style="{width: '40vw'}" header="Teklif Indir" :modal="true" :closable="true" :showHeader="true" :draggable="true">
            <br>
            <div class="p-4">
                <div class="grid">
                    <div class="col">
                        <h3>{{ seciliRaporAdi }}</h3>
                        <hr/>
                        <Button label="PDF" class="p-button-rounded p-button-danger" icon="pi pi-file-pdf" @click="teklifIndir('pdf')" />
                        <Button label="EXCEL" class="p-button-rounded p-button-success" icon="pi pi-file-excel" style="margin-left: .5em" @click="teklifIndir('xls')"/>
                        <Button label="WORD" class="p-button-rounded p-button-success" icon="pi pi-file-word" style="margin-left: .5em" @click="teklifIndir('doc')"/>
                        <Button label="GÖSTER" class="p-button-rounded p-button-info" icon="pi pi-eye" style="margin-left: .5em" @click="teklifGoster()"/>
                    </div>
                </div>

                <Button v-if="false" label="Durumu Müşteriye Gönderildi olarak DEĞİŞTİR" class="mt-3 p-button-secondary" icon="pi pi-save" @click="Islem_MusteriyeTeklifGonderildiYap" />
            </div>
        </Dialog>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import Bm_yedekparcateklifsiparisTeklif from "./bm_yedekparcateklifsiparisTeklif.vue";
import { helpers, required } from "@vuelidate/validators";

export default {
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            crmService: null,
            submitted: false,
            OBJECT_TYPE_CODE: 10011,
            mainData: {},
            selectedSiparisUrunleri: [],
            teklifUrunleriData: [],
            menuKey: 0,

			displayTeklif: false,
            displaySiparisUrun: false,

            dataSiparisUrun: {},

            displayIndir: false,
            seciliRaporAdi: null,
            seciliRaporName: null,
            seciliRaporParamName: null,

            yedekParca_CrmDataTable_options: {
				searchAttributes: ["bm_yedekparcakodu", "bm_yedekparcaadi", "bm_yedekparcaadi", "bm_yedekparcaadien"],
            },
            tezgah_CrmDataTable_options: {
				searchAttributes: ["bm_serino", "bm_urunidname"],
				forceSavedQueryId: "067671D1-4700-42C7-8B91-CE8EBAAFE78D"
            },
            nestedMenuitems: [
                {
                    label: "Kaydet",
                    icon: "pi pi-fw pi-save",
                    command: () => { this.OnSave(); },
                    visible: () => !this.isDisabled
                },
                {
                    label: "Sil",
                    icon: "pi pi-fw pi-trash",
                    command: () => { this.OnDelete(); },
                    visible: () => this.isDeleted
                },
                {
                    label: "Yedek Parça Ekle",
                    icon: "pi pi-fw pi-plus-circle",
                    command: () => { this.OpenTeklif() },
                    visible: () => !this.isDisabled
                },
                {
                    label: "İşlemler",
                    icon: "pi pi-cog",
                    items: [
                        {
                            label: "Mazak Teklif",
                            command: () => { this.GotoReport("Mazak Teklif", "03-MAZAK TEKLİF_yeni", "siparisID"); },
                            icon: "pi pi-fw pi-print"
                        },
                        {
                            label: "Mazak Exchange Teklif",
                            command: () => { this.GotoReport("Mazak Exchange Teklif", "04-MAZAK EXCHANGE TEKLİF", "siparisID"); },
                            icon: "pi pi-fw pi-print"
                        },
                        {
                            label: "FS/METR Teklif",
                            command: () => { this.GotoReport("FS/METR Teklif", "05-GTS TEKLİF", "siparisID"); },
                            icon: "pi pi-fw pi-print"
                        },
                        {
                            label: "FS/METR Exchange Teklif",
                            command: () => { this.GotoReport("FS/METR Exchange Teklif", "06-GTS EXCHANGE TEKLİF", "siparisID"); },
                            icon: "pi pi-fw pi-print"
                        },
                        {
                            label: "Form Stok",
                            command: () => { this.GotoReport("Form Stok", "07-STOK TEKLİF", "siparisID"); },
                            icon: "pi pi-fw pi-print"
                        },
                        {
                            label: "Fiyat İsteği",
                            command: () => { this.GotoReport("Fiyat İsteği", "YedekParcaTeklifIstegi_yeni", "TeklifId"); },
                            icon: "pi pi-fw pi-print",
                        },
                        {
                            label: "Tamir Teklif",
                            command: () => { this.GotoReport("Tamir Teklif", "09-TamirTeklif", "TeklifId"); },
                            icon: "pi pi-fw pi-print"
                        },
                        {
                            separator:true
                        },
                        {
                            label: "Fiyat İstendi Yap",
                            command: () => { this.Islem_FiyatIstendiYap(); },
                            icon: "pi pi-money-bill",
                        },
                        {
                            label: "Sipariş Onaylandı",
                            command: () => { this.Islem_SiparisOnaylandi(); },
                            icon: "pi pi-check-circle",
                        },
                        {
                            label: "Teklifi Kapat",
                            icon: "pi pi-lock",
                            items:[
                                {
                                    label:'Kaybedildi',
                                    command: () => { this.Islem_TeklifiKapat(2); },
                                    icon: "pi pi-lock",
                                },
                                {
                                    label:'İptal Edildi',
                                    command: () => { this.Islem_TeklifiKapat(930710003); },
                                    icon: "pi pi-lock",
                                },
                                {
                                    label:'Revize Edildi',
                                    command: () => { this.Islem_TeklifiRevizeEt(); },
                                    icon: "pi pi-lock",
                                },
                            ]
                        },
                        {
                            label: "Sipariş Oluştur",
                            command: () => { this.Islem_SiparisOlustur(); },
                            icon: "pi pi-flag",
                        },
                    ]
                },
                {
                    label: "Sipariş Yazdır",
                    icon: "pi pi-fw pi-print",
                    items: [
                        {
                            label: "Mazak Sipariş",
                            command: () => { this.GotoReport("Mazak Sipariş", "08-MAZAK SİPARİŞ", "siparisID"); },
                            icon: "pi pi-fw pi-print"
                        },
                        {
                            label: "Stok Çıkış Formu",
                            command: () => { this.GotoReport("Stok Çıkış Formu", "StokCikisFormu", "siparisID"); },
                            icon: "pi pi-fw pi-print"
                        },
                        {
                            separator:true
                        },
                        {
                            label: "Muhasebeye Mail Gönder",
                            command: () => { this.muhasebeyeMailGonder() },
                            icon: "pi pi-share-alt"
                        },
                    ]
                },
                {
                    label: "Teklif Yazdır",
                    icon: "pi pi-fw pi-print",
                    items: [
                        {
                            label: "Castrol Teklif",
                            command: () => { this.GotoReport("Castrol", "14-TEKLIF_CASTROL", "guid"); },
                            icon: "pi pi-fw pi-print"
                        },
                        {
                            label: "Nomura Teklif",
                            command: () => { this.GotoReport("Nomura", "16-TEKLIF_NOMURA", "guid"); },
                            icon: "pi pi-fw pi-print"
                        },
                        {
                            label: "Komatech Teklif",
                            command: () => { this.GotoReport("Komatech", "17-TEKLIF_KOMATECH", "guid"); },
                            icon: "pi pi-fw pi-print"
                        },
                        {
                            label: "Studer Teklif",
                            command: () => { this.GotoReport("Studer", "12-Stok Studer", "TeklifId"); },
                            icon: "pi pi-fw pi-print"
                        },
                        {
                            label: "Walter Teklif",
                            command: () => { this.GotoReport("Walter", "14-StokWalter", "guid"); },
                            icon: "pi pi-fw pi-print"
                        },
                        {
                            label: "Intercon Teklif",
                            command: () => { this.GotoReport("Intercon", "19-TEKLIF_INTERCON", "guid"); },
                            icon: "pi pi-fw pi-print"
                        }
                    ]
                },
                {
                    label: "Servis Teklif Yazdır",
                    icon: "pi pi-fw pi-print",
                    items: [
                        {
                            label: "Bakım Hizmet Teklifi",
                            command: () => { this.GotoReport("BakimHizmetTeklifi", "BakimHizmetTeklifi_yeni", "siparisID"); },
                            icon: "pi pi-fw pi-print"
                        },
                        {
                            label: "Taşıma Teklifi",
                            command: () => { this.GotoReport("TasimaTeklifi", "TasimaTeklifi_yeni", "siparisID"); },
                            icon: "pi pi-fw pi-print"
                        },
                        {
                            label: "Eğitim Teklifi",
                            command: () => { this.GotoReport("EgitimTeklifi", "EgitimTeklifi_yeni", "siparisID"); },
                            icon: "pi pi-fw pi-print"
                        },
                        {
                            label: "Servis Hizmet Teklifi",
                            command: () => { this.GotoReport("ServisHizmetTeklifi", "ServisHizmetTeklifi_yeni", "siparisID"); },
                            icon: "pi pi-fw pi-print"
                        },
                    ]
                },
            ]
        };
    },
    async created() {
        this.crmService = new CrmService();
        console.log("created");
        window.addEventListener('scroll', this.handleScroll);

        await this.OnLoad();

        if (this.mainData.tezgahName == '289421' || this.mainData.tezgahName == '292719' || this.mainData.tezgahName == '291433' || this.mainData.tezgahName == '297434') {
            this.$confirm.require({
                message: 'ATC Kol değiştirilmiştir. Parça kodunu ve önceki teklifleri kontrol ediniz.',
                header: 'UYARI',
                icon: 'pi pi-exclamation-triangle',
                acceptClass: 'p-button-info',
                acceptLabel: 'Tamam',
                rejectLabel: '_',
                accept: async () => {

                }
            });
        }
    },
    computed: {
        entityId() {
            return this.$route.params.id;
        },
        profileData() {
            return this.$store.getters.getProfile;
        },
        isDisabled: function() {
            if (this.profileData) {
                if (this.profileData.moduller) {
                    const filtered = this.profileData.moduller.filter(x => x.name == 'Y.P. Teklif / Sipariş');
                    if (filtered.length > 0) {
                        return filtered[0].isWrite == false;
                    }
                }
            }

			if (this.mainData.durum == 0) {
				return false;
			}

            return true;
		},
        isDeleted: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Y.P. Teklif / Sipariş');
					if (filtered.length > 0) {
						return filtered[0].isDelete == true;
					}
				}
			}

			return false;
		},
        siparisUrunleriCount: function(){
            return "Seçili " +  this.selectedSiparisUrunleri.length + " kaydı sil";
        },
        SM_satisYontemi: function () {
            return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, "bm_satisyontemi");
        },
        SM_durumAciklamasi: function () {
            return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, "statuscode");
        },
        SM_musteriyeTeklifGonderRaporListesi: function () {
            return  [
                {
                    "AttributeValue": '03-MAZAK TEKLİF_yeni',
                    "Value": "Mazak Teklif",
                },
                {
                    "AttributeValue": '04-MAZAK EXCHANGE TEKLİF_yeni',
                    "Value": "Mazak Exchange Teklif",
                },
                {
                    "AttributeValue": '02-GTS FİYAT',
                    "Value": "Fiyat İsteği",
                },
                {
                    "AttributeValue": '09-TamirTeklif_yeni',
                    "Value": "Tamir Teklif",
                },
            ];
        },
        SM_turu: function () {
            return  [
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 5,
                    "Value": "Form Stok",
                    "DisplayOrder": 1,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 11,
                    "Value": "FS/METR Exch",
                    "DisplayOrder": 12,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 10,
                    "Value": "FS/METR Yeni",
                    "DisplayOrder": 13,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 2,
                    "Value": "Mazak Exch",
                    "DisplayOrder": 5,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 1,
                    "Value": "Mazak Yeni",
                    "DisplayOrder": 7,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 12,
                    "Value": "Studer",
                    "DisplayOrder": 9,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 6,
                    "Value": "Tamir",
                    "DisplayOrder": 10,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 930710001,
                    "Value": "Castrol",
                    "DisplayOrder": 15,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 930710002,
                    "Value": "Komatech",
                    "DisplayOrder": 16,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 930710003,
                    "Value": "Nomura",
                    "DisplayOrder": 17,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 930710008,
                    "Value": "Walter",
                    "DisplayOrder": 20,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 930710009,
                    "Value": "Barload",
                    "DisplayOrder": 21,
                    "ObjectTypeCode": 10011
                },
                {
                    "AttributeName": "bm_tur",
                    "AttributeValue": 930710010,
                    "Value": "Servis",
                    "DisplayOrder": 21,
                    "ObjectTypeCode": 10011
                },
            ];
        },
        SM_fiyatTuru: function () {
            return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, "bm_fiyatturu");
        },
        SM_vadeSuresi: function () {
            return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, "bm_vadesuresi");
        },
        SM_pesinMi: function () {
            return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, "bm_pesinmi", "bool");
        },
        SM_urgencyCode: function () {
            return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, "bm_urgencycode");
        },
        firmaSelected: {
            get: function () {
                if (this.mainData["firmaId"]) {
                    return { "Value": this.mainData["firmaId"], "Name": this.mainData["firmaName"] };
                }
                else {
                    return null;
                }
            },
            set: function (newValue) {
                if (newValue == null) {
                    this.mainData["firmaId"] = null;
                    this.mainData["firmaName"] = null;
                    this.tezgah_CrmDataTable_options["filterParent"] = [];
                }
                else {
                    this.mainData["firmaId"] = newValue.Value;
                    this.mainData["firmaName"] = newValue.Name;

                    this.tezgah_CrmDataTable_options["filterParent"] = [
						{ 'name': 'bm_kurulummusteriid', 'filterOperator': 'eq', 'value': newValue.Value },
					];

                    this.crmService.GetEntityById({
						"entityName": "account",
						"entityId": newValue.Value,
						"columnNames": ["paymenttermscode"]})
					.then(data => {
						if (data.jsonData != null) {
                            console.log(data.jsonData);
                            debugger;
                            if (data.jsonData["paymenttermscodeId"] == "0") { //Peşin
                                this.mainData["pesinMi"] = true;
                                this.mainData["odemeKosullari"] = "PEŞİN ÖDEME";
                            }
						}
					})
					.catch(error => console.log(error))
                }
            }
        },
        ilgilikisiSelected: {
            get: function () {
                if (this.mainData["ilgiliKisiId"]) {
                    return { "Value": this.mainData["ilgiliKisiId"], "Name": this.mainData["ilgiliKisiName"] };
                }
                else {
                    return null;
                }
            },
            set: function (newValue) {
                if (newValue == null) {
                    this.mainData["ilgiliKisiId"] = null;
                    this.mainData["ilgiliKisiName"] = null;
                }
                else {
                    this.mainData["ilgiliKisiId"] = newValue.Value;
                    this.mainData["ilgiliKisiName"] = newValue.Name;
                }
            }
        },
        tezgahSelected: {
            get: function () {
                if (this.mainData["tezgahId"]) {
                    return { "Value": this.mainData["tezgahId"], "Name": this.mainData["tezgahName"] };
                }
                else {
                    return null;
                }
            },
            set: function (newValue) {
                if (newValue == null) {
                    this.mainData["tezgahId"] = null;
                    this.mainData["tezgahName"] = null;
                }
                else {
                    this.mainData["tezgahId"] = newValue.Value;
                    this.mainData["tezgahName"] = newValue.Name;

                    this.crmService.GetEntityById({
                        "entityName": "bm_unite",
                        "entityId": newValue.Value,
                        "columnNames": ["bm_urunid"]})
                    .then(data_product => {
                        if (data_product.jsonData != null) {
                            console.log(data_product.jsonData);
                            debugger;

                            this.mainData["urunId"] = data_product.jsonData["bm_urunid"];
                            this.mainData["urunName"] = data_product.jsonData["bm_urunidName"];

                            this.$refs.entity_urun.setDefaultData({ "Value": this.mainData["urunId"], "Name": this.mainData["urunName"] });
                        }
                    })
                    .catch(error => console.log(error));
                }
            }
        },
        urunSelected: {
            get: function () {
                if (this.mainData["urunId"]) {
                    return { "Value": this.mainData["urunId"], "Name": this.mainData["urunName"] };
                }
                else {
                    return null;
                }
            },
            set: function (newValue) {
                if (newValue == null) {
                    this.mainData["urunId"] = null;
                    this.mainData["urunName"] = null;
                }
                else {
                    this.mainData["urunId"] = newValue.Value;
                    this.mainData["urunName"] = newValue.Name;
                }
            }
        },
        paraBirimiSelected: {
            get: function () {
                if (this.mainData["paraBirimiId"]) {
                    return { "Value": this.mainData["paraBirimiId"], "Name": this.mainData["paraBirimiName"] };
                }
                else {
                    return null;
                }
            },
            set: function (newValue) {
                if (newValue == null) {
                    this.mainData["paraBirimiId"] = null;
                    this.mainData["paraBirimiName"] = null;
                }
                else {
                    this.mainData["paraBirimiId"] = newValue.Value;
                    this.mainData["paraBirimiName"] = newValue.Name;
                }
            }
        },
        servisTalebiSelected: {
            get: function () {
                if (this.mainData["servisTalebiId"]) {
                    return { "Value": this.mainData["servisTalebiId"], "Name": this.mainData["servisTalebiName"] };
                }
                else {
                    return null;
                }
            },
            set: function (newValue) {
                if (newValue == null) {
                    this.mainData["servisTalebiId"] = null;
                    this.mainData["servisTalebiName"] = null;
                }
                else {
                    this.mainData["servisTalebiId"] = newValue.Value;
                    this.mainData["servisTalebiName"] = newValue.Name;
                }
            }
        },

        //Sipariş Ürün Düzelt
        yedekParcaSelected: {
            get: function () {
                if (this.dataSiparisUrun["yedekParcaId"]) {
                    return { "Value": this.dataSiparisUrun["yedekParcaId"], "Name": this.dataSiparisUrun["yedekParcaName"] }
                } else{ return null; }
            },
            set: function(newValue) {
                if (newValue == null) {
                    this.dataSiparisUrun["yedekParcaId"] = null;
                    this.dataSiparisUrun["yedekParcaName"] = null;
                }
                else {
                    this.dataSiparisUrun["yedekParcaId"] = newValue.Value;
                    this.dataSiparisUrun["yedekParcaName"] = newValue.Name;

                    this.crmService.GetEntityById({
                        "entityName": "bm_yedekparca",
                        "entityId": newValue.Value,
                        "columnNames": ["bm_yedekparcakodu", "bm_varsayilanfiyat"]})
                    .then(data_yedekparca => {
                        if (data_yedekparca.jsonData != null) {
                            debugger;

                            this.dataSiparisUrun["yedekParcaKodu"] = data_yedekparca.jsonData["bm_yedekparcakodu"];

                            let varsayilanFiyat = data_yedekparca.jsonData["bm_varsayilanfiyat"];
                            varsayilanFiyat = varsayilanFiyat.replace(".", "");
                            varsayilanFiyat = varsayilanFiyat.replace(",", ".");
                            varsayilanFiyat = parseFloat(varsayilanFiyat);

                            this.dataSiparisUrun["birimFiyat"] = varsayilanFiyat;
                            
                            if (this.dataSiparisUrun["birimFiyat"] > 0 || this.dataSiparisUrun["adet"] > 0) {
                                this.dataSiparisUrun["toplamFiyat"] = this.dataSiparisUrun["adet"] * this.dataSiparisUrun["birimFiyat"];
                            }
                        }
                    })
                    .catch(error => console.log(error));
                }
            }
        },

        getDirtyTeklifUrunleriData: function () {
            if (this.teklifUrunleriData) {
                return this.teklifUrunleriData.filter(f => f.dirtyField == true);
            }

            return [];
        },

        isDisabled_FiyatIstendiYap: function () {
            if (this.mainData) {
                if (this.mainData.erpyeGonderildi == true) {
                    return true;
                }
            }

            return false;
        },
        isDisabled_SiparisOnaylandi: function () {
            if (this.mainData) {
                if (this.mainData.erpyeGonderildi == true) {
                    return true;
                }
            }

            return false;
        },
        isDisabled_TeklifiKapat: function () {
            if (this.mainData) {
                if (this.mainData.erpyeGonderildi == true) {
                    return true;
                }
            }

            return false;
        },
        isDisabled_SiparisOlustur: function () {
            if (this.mainData) {
                if (this.mainData.erpyeGonderildi == true) {
                    return true;
                }

                if (this.mainData.durumAciklamasiName != 'Sipariş Onaylandı') {
                    return true;
                }
            }

            return false;
        },
    },
    methods: {
        async OnLoad(){
            let loader = this.$loading.show({
                container: this.$refs.smsContainer
            });
            debugger;
            if (this.entityId != null) {
                try {
                    debugger;
                    let data = await this.crmService.getYedekParcaTeklifSiparisById(this.entityId);
                    if (data.yetkiVarmi == false) {
						this.$router.replace({ name: 'accessdenied' });
					}
                    
                    if (data.jsonData.length > 0) {
                        this.mainData = data.jsonData[0];

                        const pageHistoryItem = { entityLetter: 'Y.P.', entityName: 'bm_yedekparcateklifsiparis', ID: this.entityId, title: this.mainData.teklifNo + '\r\n\r\n' + this.mainData.firmaName };
                        this.$store.commit('SET_PAGEHISTORY', pageHistoryItem);

                        this.teklifUrunleriData = data.teklifUrunData;
                        if (this.mainData["firmaId"]) {
                            this.$refs.entity_firma.setDefaultData({ "Value": this.mainData["firmaId"], "Name": this.mainData["firmaName"] });
                            this.tezgah_CrmDataTable_options["filterParent"] = [
                                { 'name': 'bm_kurulummusteriid', 'filterOperator': 'eq', 'value': this.mainData["firmaId"] },
                            ];
                        }
                        

                        setTimeout(() => {
                            if (this.mainData["tezgahId"]) {
                                this.$refs.entity_tezgah.setDefaultData({ "Value": this.mainData["tezgahId"], "Name": this.mainData["tezgahName"] });
                            }
                            if (this.mainData["ilgiliKisiId"]) {
                                this.$refs.entity_ilgilikisi.setDefaultData({ "Value": this.mainData["ilgiliKisiId"], "Name": this.mainData["ilgiliKisiName"] });
                            }
                            if (this.mainData["urunId"]) {
                                this.$refs.entity_urun.setDefaultData({ "Value": this.mainData["urunId"], "Name": this.mainData["urunName"] });
                            }
                            if (this.mainData["paraBirimiId"]) {
                                this.$refs.entity_paraBirimi.setDefaultData({ "Value": this.mainData["paraBirimiId"], "Name": this.mainData["paraBirimiName"] });
                            }
                            
                            if (this.mainData["servisTalebiId"]) {
                                this.$refs.entity_servisTalebi.setDefaultData({ "Value": this.mainData["servisTalebiId"], "Name": this.mainData["servisTalebiName"] });
                            }
                        }, 500);

                        if (this.mainData["teklifTarihi"]) {
                            this.mainData["teklifTarihi"] = new Date(this.mainData.teklifTarihi);
                        }
                        if (this.mainData["siparisTarihi"]) {
                            this.mainData["siparisTarihi"] = new Date(this.mainData.siparisTarihi);
                        }

                        this.menuKey++;
                    }
                }
                catch (error) {
                    console.log(error);
                }
                finally {
                    loader.hide();
                }
            }
            else {
                loader.hide();
            }
        },
        async OnSave() {
            debugger;
            this.submitted = true;
            this.v$.$touch();
            if (this.v$.$invalid) {
                this.v$.$errors.forEach(element => {
                    this.$toast.add({ severity: "error", summary: "Eksik alanlar var.", detail: element.$message, life: 3500 });
                });
            }
            else {
                try {
                    this.confirmAllSaveProduct();

                    this.mainData["entityId"] = this.entityId;
                    const response = await this.crmService.getYedekParcaTeklifSiparisUpdate(this.mainData);
                    debugger;
                    if (response) {
                        if (response.hata == true) {
                            this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
                        }else {
                            this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
                            if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
                        }
                    }
                } catch (error) {
                    this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
                }
            }
        },
        async OnSaveTeklifSiparisUrun() {
            try {
                const response = await this.crmService.getYedekParcaTeklifSiparisUrunuUpdate(this.dataSiparisUrun);
                debugger;
                if (response) {
                    if (response.hata == true) {
                        this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
                    }else {
                        this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
                        this.displaySiparisUrun = false;
                        this.OnLoad();
                    }
                }
            } catch (error) {
                this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
            }
        },
        async OnDelete(){
            this.deleteItem('bm_yedekparcateklifsiparis', this.entityId);
        },
        async confirmAllSaveProduct() {
            debugger;
            try {
                if (this.getDirtyTeklifUrunleriData.length > 0) {
                    console.log('this.getDirtyTeklifUrunleriData.length: ' + this.getDirtyTeklifUrunleriData.length);
                    let savedCount = 0;
                    for (let index = 0; index < this.getDirtyTeklifUrunleriData.length; index++) {
                        debugger;
                        const element = this.getDirtyTeklifUrunleriData[index];
                        
                        const response = await this.crmService.getYedekParcaTeklifSiparisUrunuUpdate(element);
                        debugger;
                        if (response) {
                            if (response.hata == true) {
                                this.$toast.add({severity:'error', summary: 'Kaydetme Hatası', detail: response.hataAciklamasi, life: 5000});
                            }else {
                                this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Ürün Başarıyla Kaydedildi', life: 3000});
                                savedCount++;
                                if (savedCount == this.getDirtyTeklifUrunleriData.length) {
                                    this.OnLoad();
                                }
                            }
                        }
                    }
                }
            } catch (error) {
                this.$toast.add({severity:'error', summary: 'Silme Hatası', detail: error.message, life: 3500});
            }
        },
        async confirmSaveProduct(product) {
            debugger;
            try {
                const response = await this.crmService.getYedekParcaTeklifSiparisUrunuUpdate(product);
                debugger;
                if (response) {
                    if (response.hata == true) {
                        this.$toast.add({severity:'error', summary: 'Kaydetme Hatası', detail: response.hataAciklamasi, life: 5000});
                    }else {
                        this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Ürün Başarıyla Kaydedildi', life: 3000});
                        this.teklifUrunleriData.find(x=>x.entityId == product.entityId).dirtyField = 0;

                        const filterDataDirtyKayitlar = this.teklifUrunleriData.filter(x=>x.dirtyField == 1)
                        if (filterDataDirtyKayitlar.length == 0) {
                            this.OnLoad();
                        }
                    }
                }
            } catch (error) {
                this.$toast.add({severity:'error', summary: 'Silme Hatası', detail: error.message, life: 3500});
            }
        },
        aramaSonuclariOnCellEditInit(event){
			if(event.field == "teslimSuresi"){
				this.teklifUrunleriData[event.index][event.field] = null;
			}
		},
        aramaSonuclariOnCellEditComplete(event){
			debugger;
			if (event.field == "adet") {
				let fiyat = this.teklifUrunleriData[event.index]["birimFiyat"]; 
				this.teklifUrunleriData[event.index][event.field] = event.newValue;
				this.teklifUrunleriData[event.index]["toplamFiyat"] = event.newValue * fiyat;
			}
            else if(event.field == "yedekParcaKodu"){
				this.teklifUrunleriData[event.index][event.field] = event.newValue;
				if (this.teklifUrunleriData[event.index]["adet"] == 0) {
					this.teklifUrunleriData[event.index]["adet"] = 1;
					let fiyat = this.teklifUrunleriData[event.index]["birimFiyat"];
					this.teklifUrunleriData[event.index]["toplamFiyat"] = fiyat;
				}
			}
			else if(event.field == "teslimSuresi"){
				this.teklifUrunleriData[event.index][event.field] = event.newValue;
				if (this.teklifUrunleriData[event.index]["adet"] == 0) {
					this.teklifUrunleriData[event.index]["adet"] = 1;
					let fiyat = this.teklifUrunleriData[event.index]["birimFiyat"];
					this.teklifUrunleriData[event.index]["toplamFiyat"] = fiyat;
				}
			}
			else if(event.field == "birimFiyat"){
				this.teklifUrunleriData[event.index][event.field] = event.newValue;
				if (this.teklifUrunleriData[event.index]["adet"] == 0) {
					this.teklifUrunleriData[event.index]["adet"] = 1;
					let fiyat = event.newValue;
					this.teklifUrunleriData[event.index]["toplamFiyat"] = fiyat;
				}
				else {
					const adet = this.teklifUrunleriData[event.index]["adet"];
					let fiyat = event.newValue;
					this.teklifUrunleriData[event.index]["toplamFiyat"] = adet * fiyat;
				}
			}

            if (event.value != event.newValue) {
                this.teklifUrunleriData[event.index]["dirtyField"] = 1;
            }
		},
        async muhasebeyeMailGonder(){
            this.$confirm.require({
                message: 'Mail göndermek istediğinize emin misiniz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptClass: 'p-button-danger',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {

                    let loader = this.$loading.show({
                        container: this.$refs.mainContainer
                    });
                    
                    try {
                        const response = await this.crmService.muhasebeyeMailGonder(this.entityId);
                        debugger;
                        if (response) {
                            if (response.hata == true) {
                                this.$toast.add({severity:'error', summary: 'Hata', detail: response.hataAciklamasi, life: 5000});
                            }else {
                                this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Gönderildi', life: 3000});
                                this.displaySiparisUrun = false;
                                this.OnLoad();
                            }
                        }
                    } catch (error) {
                        this.$toast.add({severity:'error', summary: 'HATA', detail: error.message, life: 3500});
                    } finally {
                        loader.hide();
                    }
                }
            });
        },
        confirmSelectedDeleteProducts(){
            this.$confirm.require({
                message: 'Seçili ' + this.selectedSiparisUrunleri.length + ' kayıt silinecek emin misiniz?',
                header: 'Silme Onayı',
                icon: 'pi pi-exclamation-triangle',
                acceptClass: 'p-button-danger',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {

                    const entityIdList = this.selectedSiparisUrunleri.map(obj => obj.entityId);
                    const postData = {
                        parentEntityId : this.entityId,
                        list: entityIdList
                    };
                    
                    let loader = this.$loading.show({
                        container: this.$refs.smsContainer
                    });

                    try {
                        const response = await this.crmService.getYedekParcaTeklifSiparisUrunleriTopluDelete(postData);
                        debugger;
                        if (response) {
                            if (response.hata == true) {
                                this.$toast.add({severity:'error', summary: 'Silinemedi', detail: response.hataAciklamasi, life: 5000});
                            }else {
                                this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Seçili kayıtlar başarıyla silindi', life: 3000});
                                this.selectedSiparisUrunleri = [];
                                setTimeout(() => {
                                    this.OnLoad();
                                }, 1000);
                            }
                        }
                    } catch (error) {
                        this.$toast.add({severity:'error', summary: 'Bir Hata Oluştu', detail: error.message, life: 3500});
                    }
                    finally {
                        loader.hide();
                    }
                }
            });
        },
        isDisabledMenu(){
            if (this.mainData) {
                if (this.mainData.durum == 0) {
                    return true
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        },
        changeMasrafToplami(event){
            let araToplam = 0;
            if (this.mainData.araToplam == undefined || this.mainData.araToplam == null) {
                araToplam = 0;
            }
            else {
                araToplam = this.mainData.araToplam;
            }

            let masrafToplami = 0;
            if (event.value == undefined || event.value == null) {
                masrafToplami = 0;
            }
            else {
                masrafToplami = event.value;
            }

            let indirimToplami = 0;
            if (this.mainData.indirimToplami == undefined || this.mainData.indirimToplami == null) {
                indirimToplami = 0;
            }
            else {
                indirimToplami = this.mainData.indirimToplami;
            }

            this.mainData.genelToplam = (araToplam + masrafToplami) - indirimToplami;
        },
        changeIndirimToplami(event){
            let araToplam = 0;
            if (this.mainData.araToplam == undefined || this.mainData.araToplam == null) {
                araToplam = 0;
            }
            else {
                araToplam = this.mainData.araToplam;
            }

            let masrafToplami = 0;
            if (this.mainData.masrafToplami == undefined || this.mainData.masrafToplami == null) {
                masrafToplami = 0;
            }
            else {
                masrafToplami = this.mainData.masrafToplami;
            }

            let indirimToplami = 0;
            if (event.value == undefined || event.value == null) {
                indirimToplami = 0;
            }
            else {
                indirimToplami = event.value;
            }

            this.mainData.genelToplam = (araToplam + masrafToplami) - indirimToplami;
        },
        onModalKapansin(){
            //this.displayTeklif = false;
            this.OnLoad();
        },
        changePesinmi(event){
            debugger;
            if (event.value) {
                this.mainData.odemeKosullari = "PEŞİN ÖDEME";
            }
            else {
                this.mainData.odemeKosullari = "";
            }
        },
        changeDurumAciklamasi(){
            debugger;
            this.OnSave();
            setTimeout(() => {
                this.OnLoad();
            }, 500);
        },
        onRowSelectTeklifSiparisUrun(event){
            if (this.isDisabled == false) {
                this.displaySiparisUrun = true;
                this.dataSiparisUrun = { ...event.data };
            }
        },
        showUrunDuzenle(){
            if (this.dataSiparisUrun["yedekParcaId"]) {
                debugger;
                this.$refs.entity_yedekParca.setDefaultData({ "Value": this.dataSiparisUrun["yedekParcaId"], "Name": this.dataSiparisUrun["yedekParcaName"] });
            }

            if (this.dataSiparisUrun["tahminiTeslimEdilmeTarihi"]) {
                this.dataSiparisUrun["tahminiTeslimEdilmeTarihi"] = new Date(this.dataSiparisUrun.tahminiTeslimEdilmeTarihi);
            }
        },
        changeAdet(event){
            this.dataSiparisUrun.toplamFiyat = this.dataSiparisUrun.birimFiyat * event.value;
        },
        changeBirimFiyat(event){
            this.dataSiparisUrun.toplamFiyat = this.dataSiparisUrun.adet * event.value;
        },
        focusBirimFiyat(){
            this.dataSiparisUrun.birimFiyat = null;
        },
		OpenTeklif() {
            this.displayTeklif = true;
        },
        GotoFirma() {
            this.$router.push({ name: "account", params: { id: this.mainData.firmaId } });
        },
        GotoReport(Title, Name, ParamName) {
            this.displayIndir = true;
            this.seciliRaporAdi = Title;
            this.seciliRaporName = Name;
            this.seciliRaporParamName = ParamName;
        },
        teklifGoster(){
            const reportUrl = process.env.VUE_APP_WEBAPP_URL + "/SSRS_ReportViewer.aspx?name=" + this.seciliRaporName + "&" + this.seciliRaporParamName + "=" + this.entityId;
            window.open(reportUrl, "qqqq", "_blank");
        },
        teklifIndir(fileType){
            let loader = this.$loading.show({
                container: this.$refs.mainContainer
            });

            console.log(fileType);

            debugger;
            this.crmService.GetSsrsReport(this.seciliRaporName, this.seciliRaporParamName + '=' + this.entityId, fileType).then(responseSSRS => {
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";

                const url = window.URL.createObjectURL(responseSSRS.data);
                a.href = url;
                a.download = this.seciliRaporAdi + '_' + this.mainData.teklifNo + "." + fileType;
                a.click();

                a.remove();
                window.URL.revokeObjectURL(url);
                loader.hide();

                this.displayIndir = false;

                if (this.mainData.durumAciklamasiName == 'Fiyat İstendi' && this.seciliRaporName != 'YedekParcaTeklifIstegi_yeni' && fileType == 'pdf') {
                    this.$confirm.require({
                        message: 'Durum "Müşteriye Teklif Gönderildi" dönüştürülecek emin misiniz?',
                        header: 'Onay',
                        icon: 'pi pi-exclamation-triangle',
                        acceptClass: 'p-button-danger',
                        acceptLabel: 'Evet',
                        rejectLabel: 'Hayır',
                        accept: async () => {
                            this.Islem_MusteriyeTeklifGonderildiYap();
                        }
                    });
                }
            })
            .catch(error => {
                loader.hide();
                debugger;
                console.log(error);
            });
        },
        async Islem_TeklifiKapat(statusCode) {
            this.$confirm.require({
                message: 'Teklif kapatılacak emin misiniz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptClass: 'p-button-danger',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    let loader = this.$loading.show({
                        container: this.$refs.smsContainer
                    });

                    try {
                        const postData = {
                            logicalName: 'bm_yedekparcateklifsiparis',
                            entiyId: this.entityId,
                            stateCode: 1,
                            statusCode: statusCode
                        };

                        await this.crmService.updateSiparisiGeriAlOpsiyonlariTemizle(this.entityId);

                        const response = await this.crmService.ChangeStateStatus(postData);
                        debugger;
                        if (response) {
                            if (response.hata == true) {
                                this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
                            }else {
                                this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Durum Başarıyla Değiştirildi', life: 3000});
                                setTimeout(() => {
                                    this.OnLoad();
                                }, 1000);
                            }
                        }
                    } catch (error) {
                        this.$toast.add({severity:'error', summary: 'Bir Hata Oluştu', detail: error.message, life: 3500});
                    }
                    finally {
                        loader.hide();
                    }
                }
            });
        },
        async Islem_TeklifiRevizeEt() {
            this.$confirm.require({
                message: 'Teklif Revize edilecek emin misiniz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptClass: 'p-button-danger',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    let loader = this.$loading.show({
                        container: this.$refs.smsContainer
                    });

                    try {
                        const response = await this.crmService.updateDurumTeklifRevize(this.entityId);
                        debugger;
                        if (response) {
                            if (response.hata == true) {
                                this.$toast.add({severity:'error', summary: 'Revize Edilemedi', detail: response.hataAciklamasi, life: 5000});
                            }else {
                                this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Durum Başarıyla Revize Edildi', life: 3000});
                                setTimeout(() => {
                                    this.$router.push({ name: 'bm_yedekparcateklifsiparis', params: { id: response.olusturulanId } });
                                }, 1000);
                            }
                        }
                    } catch (error) {
                        this.$toast.add({severity:'error', summary: 'Bir Hata Oluştu', detail: error.message, life: 3500});
                    }
                    finally {
                        loader.hide();
                    }
                }
            });
        },
        async Islem_SiparisOlustur() {
            debugger;
            this.$confirm.require({
                message: 'Sipariş Oluşturmak istediğinize emin misiniz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptClass: 'p-button-danger',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    let loader = this.$loading.show({
                        container: this.$refs.smsContainer
                    });

                    try {
                        const response = await this.crmService.createSiparisOlustur(this.entityId);
                        debugger;
                        if (response) {
                            if (response.hata == true) {
                                loader.hide();
                                this.$toast.add({severity:'error', summary: 'Sipariş Oluşturma Hatası', detail: response.hataAciklamasi, life: 5000});
                            }else {
                                loader.hide();
                                this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Sipariş Başarıyla oluşturuldu', life: 3000});
                                setTimeout(() => {
                                    this.OnLoad();
                                }, 1000);
                            }
                        }
                    } catch (error) {
                        this.$toast.add({severity:'error', summary: 'Silme Hatası', detail: error.message, life: 3500});
                    }
                    finally {
                        loader.hide();
                    }
                }
            });
        },
        async Islem_FiyatIstendiYap() {
            this.$confirm.require({
                message: 'Durum "Fiyat İstendi" olarak dönüştürülecek ve varsa SİPARİŞ NUMARASI silinecektir emin misiniz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptClass: 'p-button-danger',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    let loader = this.$loading.show({
                        container: this.$refs.smsContainer
                    });

                    try {
                        const response = await this.crmService.updateDurumFiyatIstendi(this.entityId);
                        debugger;
                        if (response) {
                            if (response.hata == true) {
                                this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
                            }else {
                                this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Durum Başarıyla Fiyat İstendi yapıldı', life: 3000});
                                setTimeout(() => {
                                    this.OnLoad();
                                }, 1000);
                            }
                        }
                    } catch (error) {
                        this.$toast.add({severity:'error', summary: 'Bir Hata Oluştu', detail: error.message, life: 3500});
                    }
                    finally {
                        loader.hide();
                    }
                }
            });
        },
        async Islem_SiparisOnaylandi() {
            this.$confirm.require({
                message: 'Durum "Sipariş Onaylandı" olarak dönüştürülecek emin misiniz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptClass: 'p-button-danger',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    let loader = this.$loading.show({
                        container: this.$refs.smsContainer
                    });

                    try {
                        const response = await this.crmService.updateDurumSiparisOnaylandi(this.entityId);
                        debugger;
                        if (response) {
                            if (response.hata == true) {
                                this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
                            }else {
                                this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Durum Başarıyla Sipariş Onaylandı yapıldı', life: 3000});
                                setTimeout(() => {
                                    this.OnLoad();
                                }, 1000);
                            }
                        }
                    } catch (error) {
                        this.$toast.add({severity:'error', summary: 'Bir Hata Oluştu', detail: error.message, life: 3500});
                    }
                    finally {
                        loader.hide();
                    }
                }
            });
        },
        async Islem_MusteriyeTeklifGonderildiYap() {
            let loader = this.$loading.show({
				container: this.$refs.smsContainer
			});

            try {
                const response = await this.crmService.updateDurumMusteriyeTeklifGonderildi(this.entityId);
                debugger;
                if (response) {
                    if (response.hata == true) {
                        this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
                    }else {
                        this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Durum Başarıyla Müşteriye Teklif Gönderildi yapıldı', life: 3000});
                        setTimeout(() => {
                            this.OnLoad();
                        }, 1000);
                    }
                }
            } catch (error) {
                this.$toast.add({severity:'error', summary: 'Bir Hata Oluştu', detail: error.message, life: 3500});
            }
            finally {
                loader.hide();
            }
        },
        formatNumber(value) {
            if (value != undefined) {
                return value.toLocaleString("tr-TR");
            }
        },
        getCurrency() {
            let paraBirimi = "";
            switch (this.mainData.paraBirimiName) {
                case "Dolar":
                    paraBirimi = " $";
                    break;
                case "Euro":
                    paraBirimi = " €";
                    break;
                case "İsviçre Frankı":
                    paraBirimi = " SFr.";
                    break;
                case "Türk Lirası":
                    paraBirimi = " ₺";
                    break;
                case "Yen":
                    paraBirimi = " ¥";
                    break;
                default:
                    break;
            }
            return paraBirimi;
        },
        getCurrency2(paraBirimiUzun) {
			let paraBirimi = '';
			switch (paraBirimiUzun) {
				case "Dolar":
					paraBirimi = " $"
					break;
				case "Euro":
					paraBirimi = " €"
					break;
				case "İsviçre Frankı":
					paraBirimi = " SFr."
					break;
				case "Türk Lirası":
					paraBirimi = " ₺"
					break;
				case "Yen":
					paraBirimi = " ¥"
					break;

				default:
					paraBirimi = "";
					break;
			}
			return paraBirimi;
		},
        handleScroll() {
            const el = document.getElementById('stickBar');

            if (window.scrollY > 50) {
                el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
            }
            else {
                el.style.boxShadow = '';
            }
        },
    },
    watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == 'Y.P. Teklif / Sipariş');
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
    validations() {
        return {
            mainData: {
                teklifBasligi: {
                    required: helpers.withMessage('Teklif Başlığı alanı dolu olmalıdır!', required),
                },
                teklifTarihi: {
                    required: helpers.withMessage('Teklif Tarihi alanı dolu olmalıdır!', required),
                },
                paraBirimiId: {
                    required: helpers.withMessage('Para Birimi alanı dolu olmalıdır!', required),
                },
                firmaId: {
                    required: helpers.withMessage('Firma alanı dolu olmalıdır!', required),
                }
            },
        };
    },
    components: { Bm_yedekparcateklifsiparisTeklif }
}
</script>

<style lang="scss" scoped>
.template .p-button i {
    line-height: 2.25rem;
}
.template .p-button.google {
    background: linear-gradient(to left, var(--purple-600) 50%, var(--purple-700) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border-color: var(--purple-700);
}
.template .p-button.google:hover {
    background-position: left bottom;
}
.template .p-button.google i {
    background-color: var(--purple-700);
}
.template .p-button.google:focus {
    box-shadow: 0 0 0 1px var(--purple-400);
}
</style>
